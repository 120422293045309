// Custom styles for this theme

.header > .container-fluid,
.sidebar-header {
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.header > .container-fluid + .container-fluid {
  min-height: 3rem;
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-toggler {
  @include ltr-rtl('margin-left', auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl('margin-right', auto);
  }
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}

.separator {
  border: 0.6px solid var(--cui-tertiary-bg);
}

.add-note {
  cursor: pointer;
  margin: 5px 5px -5px 0px;
}

.helper-text {
  font-size: 11px;
  font-style: italic;
}

.card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.custom-accordion-header {
  background-color: #2e6530;
  color: white;
}

.custom-accordion-header.active {
  background-color: #4caf50;
  color: white;
}
